.footer {
  margin-top: 110px;
  padding-bottom: 170px;
}

.footer p {
  font-family: 'Roboto Mono Light';
  font-size: 12px;
  line-height: 10px;
  opacity: 1;
}

@media screen and (max-width: 900px) {
  .footer {
    margin-top: 60px;
    padding-bottom: 50px;
  }
}
