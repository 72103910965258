.Layout {
  background-repeat: no-repeat;
  background-size: 1000%;
  background-position-x: 10%;
  background-position-y: 60%;
  transition: all 1500ms ease-out;
  min-height: 100vh;
}

@media screen and (max-width: 900px) {
  .Layout {
    background-size: auto 127%;
    background-position-x: 50% !important;
    background-position-y: 55%;
  }
}
