.Logo {
  margin-top: 148px;
  width: 350px;
  height: auto;
}

@media screen and (max-width: 900px) {
  .Logo {
    margin-top: 73px;
    width: 290px;
  }
}
