.contentBlock {
  width: 540px;
  margin-left: 260px;
  transition: opacity 700ms;
}

@media screen and (max-width: 900px) {
  .contentBlock {
    width: auto;
    margin-left: 0px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
